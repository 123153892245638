<template>
  <div class="level divide-x">
    <div v-for="level in levels" :key="level" class="level-item">
      <slot :level="level">
        <div>{{ level }}</div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    levels: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>

<style scoped>
.level {
  @apply flex items-center;
}
.level-item {
  @apply flex-1 flex flex-col items-center;
}
</style>
