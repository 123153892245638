<template>
  <base-box>
    <p slot="header">{{ $t('dashboard.resources.name') }}</p>
    <div class="overflow-x-auto" style="max-height: 437px;">
      <table class="dashboard-resources">
        <tr class="text-sm">
          <th class="text-left">{{ $t('dashboard.resources.user') }}</th>
          <th>{{ $t('dashboard.resources.task') }}</th>
          <th>{{ $t('dashboard.resources.hours') }}</th>
          <th>{{ $t('dashboard.resources.incompleted') }}</th>
          <th>{{ $t('dashboard.resources.completed') }}</th>
          <th>{{ $t('dashboard.resources.status') }}</th>
        </tr>
        <tr v-for="data in tableData" :key="data.id">
          <td class="dashboard-resouces__name">
            <div>{{ getUserName(data) }}</div>
            <div class="text-gray-600 v-clamp-word-break text-sm">
              {{ data.user.email }}
            </div>
          </td>
          <td class="text-center">{{ data.total || '-' }}</td>
          <td class="text-center whitespace-no-wrap">
            <span v-if="data.estimateTime">
              {{ getHours(data.estimateTime) }}
              {{ $t('dashboard.resources.hr') }}.
            </span>
            <span v-else>-</span>
          </td>
          <td class="text-center">{{ data.inComplete || '-' }}</td>
          <td class="text-center">{{ data.complete || '-' }}</td>
          <td class="text-center">
            <span
              class="dashboard-resources__status"
              :class="getStatusClass(data)"
            >
              {{ getStatus(data) }}
            </span>
          </td>
        </tr>
      </table>
    </div>
  </base-box>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      defualt() {
        return [];
      }
    }
  },
  methods: {
    getUserName(data) {
      return data.user.name;
    },
    getStatus(data) {
      return [
        this.$t('dashboard.resources.unknow'),
        this.$t('dashboard.resources.pendind'),
        this.$t('dashboard.resources.active'),
        this.$t('dashboard.resources.leave'),
        this.$t('dashboard.resources.deactivated')
      ][data.status];
    },
    getStatusClass(data) {
      const { status } = data;
      if (status < 2) return '';
      return 'status--' + this.getStatus(data).toLowerCase();
    },
    getHours(minutes) {
      return Math.round(minutes / 60);
    }
  }
};
</script>

<style scoped>
.dashboard-resources {
  @apply w-full;
}
td,
th {
  @apply px-2 py-3;
}
th {
  @apply font-medium;
}
td {
  @apply border-t;
}
.dashboard-resouces__name {
  @apply truncate;
  max-width: 300px;
}
.dashboard-resources__status {
  @apply py-1 px-3 rounded-full bg-gray-f5 text-gray-label text-xs;
}
.status--active {
  color: #88b864;
  background-color: #eaf5e3;
}
.status--กำลังใช้งาน {
  color: #88b864;
  background-color: #eaf5e3;
}
.status--leave {
  color: #f65754;
  background-color: #ffdddc;
}
.status--ออกจากบอร์ด {
  color: #f65754;
  background-color: #ffdddc;
}
.status--deactivated {
  color: #e86b1a;
  background-color: #fef0e3;
}
.status--ลบบัญชีผู้ไช้ {
  color: #e86b1a;
  background-color: #fef0e3;
}
</style>
