var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-box',[(_vm.isOverview)?_c('p',{attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.$t('title_overview')))]):_c('p',{attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.$t('dashboard.insight.name')))]),(!_vm.isOverview)?_c('div',_vm._l((_vm.insightDisplays),function(ref){
var key = ref.key;
var color = ref.color;
var total = ref.total;
var title = ref.title;
return _c('div',{key:key,staticClass:"dashboard-insight__progress"},[_c('div',{staticClass:"flex justify-between text-sm mb-1"},[_c('span',[_vm._v(_vm._s(title))]),_c('span',[_vm._v(" "+_vm._s(_vm.findPercentage(_vm.insight[key], _vm.insight[total]))+"% ")])]),_c('bar-progress',{attrs:{"rounded":"","color":color,"height":2,"value":_vm.insight[key],"maximum":_vm.insight[total]}})],1)}),0):_c('div',[(_vm.insight.total > 0)?_c('div',{staticClass:"dashboard-insight__container",class:{ 'justify-between': !_vm.isOverview }},_vm._l((_vm.insightDisplays),function(ref){
var key = ref.key;
var color = ref.color;
var total = ref.total;
var title = ref.title;
return _c('div',{key:key,staticClass:"dashboard-insight__progress",class:{ 'py-4': _vm.isOverview },staticStyle:{"z-index":"-5"}},[_c('div',{staticClass:"flex justify-between text-sm mb-1"},[_c('span',[_vm._v(_vm._s(title))]),_c('span',[_vm._v(" "+_vm._s(_vm.findPercentage(_vm.insight[key], _vm.insight[total]))+"% ")])]),_c('bar-progress',{attrs:{"rounded":"","color":color,"height":2,"value":_vm.insight[key],"maximum":_vm.insight[total]}})],1)}),0):_c('div',{staticClass:"text-center py-6"},[_c('base-icon',{attrs:{"size":"155"}},[_c('icon-empty-task-status')],1),_c('div',[_c('p',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('empty_status.title')))]),_c('div',{staticClass:"text-sm text-gray-caption",domProps:{"innerHTML":_vm._s(_vm.$t('empty_status.description'))}})])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }