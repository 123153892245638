<template>
  <layout-level :levels="summaryLevels" class="border py-5 rounded">
    <template #default="{ level }">
      <div>
        <div>
          <span class="text-black text-sm">{{
            summaryTitle[level].primary
          }}</span>
          <span v-if="summaryTitle[level].today" class="text-gray-500 text-xs">
            ({{ $t('dashboard.sumary.today') }})
          </span>
        </div>
        <div>
          <span class="text-4xl font-medium mr-2">{{ summary[level] }}</span>
          <span
            v-if="summaryTitle[level].primary != $t('summary_title.all')"
            class="text-lg"
            >{{ $t('dashboard.sumary.task') }}</span
          >
          <span v-else class="text-lg">{{ $tc('board', summary[level]) }}</span>
        </div>
      </div>
    </template>
  </layout-level>
</template>

<script>
import LayoutLevel from '@/components/layout/layout-level';

export default {
  components: {
    LayoutLevel
  },
  props: {
    summary: {
      type: Object,
      default() {
        return {
          all: 0,
          allboard: 0,
          createToday: 0,
          dueDateToday: 0,
          late: 0,
          upcoming: 0,
          inprogress: 0,
          done: 0,
          overdue: 0
        };
      }
    },
    isOverview: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    summaryLevels() {
      if (this.isOverview) {
        delete this.summary['createToday'];
      }
      return Object.keys(this.summary).sort(
        (a, b) => this.summaryOrder[a] - this.summaryOrder[b]
      );
    },
    summaryTitle() {
      const getTexts = (primary, today) => ({ primary, today });
      return {
        all: getTexts(this.$t('dashboard.sumary.all')),
        allboard: getTexts(this.$t('summary_title.all')),
        inprogress: getTexts(this.$t('summary_title.inProgress')),
        done: getTexts(this.$t('summary_title.done')),
        overdue: getTexts(this.$t('summary_title.overdue')),
        late: getTexts(this.$t('dashboard.sumary.late')),
        dueDateToday: getTexts(this.$t('dashboard.sumary.date'), true),
        upcoming: getTexts(this.$t('dashboard.sumary.upcomimg')),
        createToday: getTexts(this.$t('dashboard.sumary.created'), true)
      };
    },
    summaryOrder() {
      if (this.isOverview) {
        return {
          allboard: 0,
          inprogress: 1,
          done: 2,
          overdue: 3
        };
      } else {
        return {
          all: 0,
          late: 1,
          dueDateToday: 2,
          upcoming: 3,
          createToday: 4
        };
      }
    }
  }
};
</script>

<style></style>
<i18n>
{
  "en": {
    "board": "board |  boards",
    "summary_title":{
      "all": "All Boards",
      "inProgress":"In progress",
      "done": "Done",
      "overdue": "Overdue"
    }
  },
  "th": {
    "board": "บอร์ด",
    "summary_title":{
      "all": "บอร์ดทั้งหมด",
      "inProgress":"งานที่กำลังดำเนินการ",
      "done": "งานที่เสร็จทั้งหมด",
      "overdue": "งานที่เกินกำหนด"
    }
  }
}
</i18n>
