<template>
  <base-box>
    <p v-if="isOverview" slot="header">{{ $t('title_overview') }}</p>
    <p v-else slot="header">{{ $t('dashboard.insight.name') }}</p>
    <div v-if="!isOverview">
      <div
        v-for="{ key, color, total, title } in insightDisplays"
        :key="key"
        class="dashboard-insight__progress"
      >
        <div class="flex justify-between text-sm mb-1">
          <span>{{ title }}</span>
          <span> {{ findPercentage(insight[key], insight[total]) }}% </span>
        </div>
        <bar-progress
          rounded
          :color="color"
          :height="2"
          :value="insight[key]"
          :maximum="insight[total]"
        />
      </div>
    </div>
    <div v-else>
      <div
        v-if="insight.total > 0"
        class="dashboard-insight__container"
        :class="{ 'justify-between': !isOverview }"
      >
        <div
          v-for="{ key, color, total, title } in insightDisplays"
          :key="key"
          class="dashboard-insight__progress"
          :class="{ 'py-4': isOverview }"
          style="z-index: -5"
        >
          <div class="flex justify-between text-sm mb-1">
            <span>{{ title }}</span>
            <span> {{ findPercentage(insight[key], insight[total]) }}% </span>
          </div>
          <bar-progress
            rounded
            :color="color"
            :height="2"
            :value="insight[key]"
            :maximum="insight[total]"
          />
        </div>
      </div>
      <div v-else class="text-center py-6">
        <base-icon size="155">
          <icon-empty-task-status />
        </base-icon>
        <div>
          <p class="mb-2">{{ $t('empty_status.title') }}</p>
          <div
            class="text-sm text-gray-caption"
            v-html="$t('empty_status.description')"
          />
        </div>
      </div>
    </div>
  </base-box>
</template>

<script>
import BarProgress from '@/components/bar-progress';
import IconEmptyTaskStatus from '@/components/icons/IconEmptyTaskStatus';
export default {
  components: { BarProgress, IconEmptyTaskStatus },
  props: {
    insight: {
      type: Object,
      default() {
        return {
          boardID: 0,
          complete: 0,
          date: new Date(),
          expectTime: 0,
          late: 0,
          spendTime: 0,
          inprogress: 0,
          total: 0
        };
      }
    },
    isOverview: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    insightDisplays() {
      const getDisplay = (key, total, title, color) => ({
        key,
        title,
        color,
        total
      });
      const late = getDisplay(
        'late',
        'total',
        this.$t('dashboard.insight.late'),
        '#FF8886'
      );

      const complete = getDisplay(
        'complete',
        'total',
        this.$t('dashboard.insight.complete'),
        '#27AE60'
      );

      const spendTime = getDisplay(
        'spendTime',
        'expectTime',
        this.$t('dashboard.insight.time'),
        '#F2C94C'
      );

      const inprogress = getDisplay(
        'inprogress',
        'total',
        this.$t('inProgress'),
        '#8E979D'
      );
      return this.isOverview
        ? [inprogress, complete]
        : [late, complete, spendTime];
    }
  },
  methods: {
    findPercentage(value, total) {
      const maximum = Math.max(total, 1);
      return total ? parseFloat(((value / maximum) * 100).toFixed(2)) : 0;
    }
  }
};
</script>

<style scoped>
.dashboard-insight__container {
  @apply py-3 px-1 flex flex-col h-full;
}
</style>
<i18n>
{
  "en": {
    "title_overview":"Status",
    "inProgress":"In progress",
    "empty_status": {
      "title": "Data Not Found",
      "description": "Summary of overall board activity. This function requires board information and other data to be displayed. <br/> Please create a board to view your board summary."
    }
  },
  "th": {
    "title_overview":"สถานะงาน",
    "inProgress":"กำลังทำอยู่",
    "empty_status": {
      "title": "ไม่พบข้อมูล",
      "description": "การแสดงผลรวมของบอร์ดในเวิร์คสเปซ ต้องมีข้อมูลบอร์ดและงานต่างๆ<br/>โปรดสร้างบอร์ดเพื่อดูข้อมูลผลรวม"
    }
  }
}
</i18n>
