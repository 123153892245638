<template>
  <base-box ref="baseBox">
    <p slot="header">{{ $t('dashboard.task.name') }}</p>
    <bar-chart
      :width="350"
      :height="180"
      :chart-data="chartData"
      :options="chartOptions"
      :styles="chartStyles"
    />
  </base-box>
</template>

<script>
import BarChart from '@/components/chart/chart-bar';
export default {
  components: {
    BarChart
  },
  props: {
    taskcount: {
      type: Object,
      default() {
        return {
          late: 0,
          today: 0,
          upcoming: 0
        };
      }
    }
  },
  computed: {
    chartData() {
      const { late, today, upcoming } = this.taskcount;
      return {
        datasets: [
          {
            data: [late, today, upcoming],
            backgroundColor: ['#FE8080', '#4EB0FA', '#FDDF83']
          }
        ]
      };
    },
    chartOptions() {
      const tickPadding = 14;
      const drawTicks = false;
      return {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              type: 'category',
              labels: [
                this.$t('dashboard.task.late'),
                this.$t('dashboard.task.today'),
                this.$t('dashboard.task.upcoming')
              ],
              gridLines: {
                drawTicks,
                drawOnChartArea: false,
                color: '#E9EBF0'
              },
              maxBarThickness: 40,
              ticks: {
                padding: tickPadding,
                fontColor: '#999B9B'
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                display: true,
                precision: true,
                fontColor: '#C1C3C3',
                beginAtZero: true,
                padding: tickPadding,
                maxTicksLimit: 4
              },
              gridLines: {
                drawTicks,
                zeroLineColor: '#E9EBF0',
                drawBorder: false,
                color: '#E9EBF0'
              }
            }
          ]
        }
      };
    },
    chartStyles() {
      return {
        paddingTop: '1rem'
      };
    }
  }
};
</script>

<style></style>
