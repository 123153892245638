<template>
  <div class="container dashboard">
    <dashboard-summary :summary="getSummary" />
    <div class="dashboard__row">
      <dashboard-taskcount :taskcount="getTaskcount" />
      <dashboard-insight :insight="getInsight" />
    </div>
    <dashboard-resources :table-data="getResources" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import MembersVisible from '@/mixins/MembersVisible';
import DashboardSummary from '@/components/dashboard/dashboard-summary';
import DashboardInsight from '@/components/dashboard/dashboard-insight.vue';
import DashboardResources from '@/components/dashboard/dashboard-resources.vue';
import DashboardTaskcount from '@/components/dashboard/dashboard-taskcount.vue';

const { mapGetters, mapActions } = createNamespacedHelpers('dashboard');

export default {
  mixins: [MembersVisible],
  components: {
    DashboardSummary,
    DashboardInsight,
    DashboardResources,
    DashboardTaskcount
  },
  computed: {
    ...mapGetters(['getSummary', 'getInsight', 'getResources', 'getTaskcount'])
  },
  async mounted() {
    await this.fetchSummary();
    await this.fetchInsight();
    await this.fetchResources();
    await this.fetchTaskcount();
  },
  methods: {
    ...mapActions([
      'fetchSummary',
      'fetchInsight',
      'fetchResources',
      'fetchTaskcount'
    ])
  }
};
</script>

<style scoped>
.dashboard {
  @apply px-5 mx-auto;
}
.dashboard > div {
  @apply my-5;
}
.dashboard__row {
  @apply flex -mx-3;
}
.dashboard__row > div {
  @apply px-3 w-1/2;
}
</style>
